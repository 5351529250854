<template>
    <el-row style="border-bottom: 1px solid silver; margin-bottom: 20px;">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <label
                style="display: inline-block; font-size: 20px; border-bottom: 5px solid rgb(142, 195, 31); padding-bottom: 10px; margin-bottom: -1px; ">{{
                    title }}</label>
        </el-col>
        <el-col :xs="0" :sm="0" :md="12" :lg="12" :xl="12">
            <el-breadcrumb :separator-icon="ArrowRight" style="margin-top: 10px; float: right;">
                <el-breadcrumb-item v-for="(item, index) in titleList" :key="index">
                    <label v-if="index === 0">{{ $t("common.location") }}：{{ item }}</label>
                    <label v-else>{{ item }}</label>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
    </el-row>
</template>
  
<script>
import { shallowRef } from 'vue'
import { ArrowRight } from '@element-plus/icons-vue'

export default {
    name: "Breadcrumb",
    props: {
        title: {
            type: String,
        },
        titleList: {
            type: Array
        }
    },
    data() {
        return {
            ArrowRight: shallowRef(ArrowRight)
        };
    },
};
</script>

<style scoped>
.el-divider--horizontal {
    margin: -1px 0 24px 99px;
}
</style>