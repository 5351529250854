<template>
    <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <Breadcrumb :title="$t('menu.contactUs')"></Breadcrumb>
            <table>
                <tr v-for="(item, index) in contact.items" :key="index">
                    <td style="width: 5em; padding-bottom: 20px; vertical-align: top;">{{ $t('contactUs.' + item.key) }}:
                    </td>
                    <td style="padding-bottom: 20px;">
                        <div v-for="(value, index) in item.value" :key="index"
                            :style="{ paddingTop: index === 0 ? '0' : '10px' }">
                            <div v-if="item.isPhone" style="color: blue" @click="callPhone(value)">
                                {{ $t('contactUs.' + value) }}
                            </div>
                            <div v-else>{{ $t('contactUs.' + value) }}</div>
                        </div>
                    </td>
                </tr>
            </table>
            <el-image class="animate__animated animate__pulse" v-for="(image, index) in contact.images" :key="index"
                :src="image" fit="contain"></el-image>
        </el-col>
    </el-row>
</template>

<script>
import contact from "/public/Json/contactUs.json";
import Breadcrumb from "../../../components/Breadcrumb.vue";

export default {
    name: "Contact",
    components: {
        Breadcrumb,
    },
    data() {
        return {
            contact,
        };
    },
    methods: {
        callPhone(phoneNumber) {
            window.location.href = "tel://" + phoneNumber;
        }
    }
};
</script>